import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    Stack,
    Tooltip,
    IconButton,
    Button,
    Typography,
    Divider,
    Select,
    MenuItem,
} from "@mui/material";
import { Delete, MoreTime, Add } from "@mui/icons-material";
import { DaysPickerProps } from "./types";

export function DaysPicker({
    periods,
    teacherAvaliability,
    isEditing,
    setChangedAvailabilities,
    setRemoveAvaliabilities,
}: DaysPickerProps) {
    //value inicial das TABS
    
    const [tabValue, setTabValue] = useState<string>("segunda");
    const [avaliabilities, setAvaliabilities] = useState(
        teacherAvaliability || []
    );
    const [selectAvaliability, setSelectAvaliability] = useState<
        number | string
    >("");

    const [teste, setTeste] = useState<{ [key: string]: any[] }>({});
    useEffect(() => {
        const newTeste = {
            segunda: [],
            terça: [],
            quarta: [],
            quinta: [],
            sexta: [],
            sábado: [],
            domingo: [],
        };
        avaliabilities?.forEach((item) => {
            const dayOfWeek = item.dayOfWeek;
            if (dayOfWeek in newTeste) {
                newTeste[dayOfWeek].push(item);
            }
        });
        
        setTeste(newTeste);
    }, [avaliabilities]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setSelectAvaliability("");
    };

    const onAddNewPeriod = useCallback(
        (periodId?: number, everyday?: boolean) => {
            if (!selectAvaliability && !periodId) return;
            const { start, end, id } = periods?.find(
                (x) => x.id === (everyday ? periodId : selectAvaliability)
            );
            const newPeriod = periodId
                ? Object.keys(teste)
                      ?.filter((alreadyExists) => alreadyExists !== tabValue)
                      ?.map((day) => ({
                          classPeriodId: id,
                          start,
                          end,
                          dayOfWeek: day,
                      }))
                : [{ classPeriodId: id, start, end, dayOfWeek: tabValue }];
            setAvaliabilities((prev) => {
                const uniquePeriods = newPeriod?.filter(
                    (newPeriodItem) =>
                        !prev?.some(
                            (prevPeriod) =>
                                prevPeriod.classPeriodId ===
                                    newPeriodItem?.classPeriodId &&
                                prevPeriod.dayOfWeek ===
                                    newPeriodItem?.dayOfWeek
                        )
                );
                return [...prev, ...uniquePeriods];
            });
            setChangedAvailabilities((prev) =>
                newPeriod?.some((newPeriodItem) =>
                    prev?.some(
                        (prevPeriod) =>
                            prevPeriod.classPeriodId ===
                                newPeriodItem.classPeriodId &&
                            prevPeriod.dayOfWeek === newPeriodItem.dayOfWeek
                    )
                )
                    ? prev
                    : [...prev, ...newPeriod]
            );
            setSelectAvaliability("");
        },
        [periods, selectAvaliability, setChangedAvailabilities, tabValue, teste]
    );

    const deleteAvaliability = useCallback(
        (period: any) => {
            setAvaliabilities(avaliabilities?.filter((x) => x !== period));
            setChangedAvailabilities((prev) => {
                return prev?.filter((x) => x !== period);
            });

            setRemoveAvaliabilities((prev) => [...prev, period]);
        },
        [avaliabilities, setChangedAvailabilities, setRemoveAvaliabilities]
    );

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginBottom: "1rem",
                    }}
                >
                    <TabList
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {Object.keys(teste).map((day, index) => (
                            <Tab key={index} label={day} value={day} />
                        ))}
                    </TabList>
                </Box>
                {isEditing && (
                    <Stack spacing={2} direction={"row"}>
                        <Select
                            size="small"
                            value={selectAvaliability}
                            onChange={(e: any) => {
                                setSelectAvaliability(e.target.value);
                            }}
                            sx={{
                                minWidth: "110px",
                                width: "100%",
                                maxWidth: "300px",
                            }}
                            disabled={false}
                        >
                            {periods
                                ?.filter(
                                    (y) =>
                                        !teste[tabValue]
                                            .map((z) => z.classPeriodId)
                                            .includes(y.id)
                                )
                                ?.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.start} - {x.end}
                                    </MenuItem>
                                ))}
                        </Select>

                        <Button
                            disabled={!isEditing}
                            variant="contained"
                            onClick={() => {
                                onAddNewPeriod();
                            }}
                        >
                            <Add />
                        </Button>
                    </Stack>
                )}
                {avaliabilities?.map((avaliability) => (
                    <TabPanel
                        value={avaliability.dayOfWeek}
                        sx={{ padding: "10px 5px" }}
                    >
                        <Stack
                            key={avaliability.id}
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Typography key={avaliability.id}>
                                {avaliability.start} - {avaliability.end}
                            </Typography>

                            {isEditing && (
                                <Stack direction={"row"}>
                                    <Tooltip
                                        title={
                                            "Lançar horário para todos os dias"
                                        }
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                onAddNewPeriod(
                                                    avaliability.classPeriodId,
                                                    true
                                                );
                                            }}
                                        >
                                            <MoreTime color="success" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Remover disponibilidade"}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                deleteAvaliability(
                                                    avaliability
                                                );
                                            }}
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                        </Stack>
                        <Divider />
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
}
