import Api from "../../services/api";

export function getClassSchedules(params) {
    return new Promise((resolve, reject) => {
        Api.get("/class-schedules", { params })
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });
    });
}

export function deleteClassSchedules(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/class-schedules/${id}`)
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });
    });
}
