import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section`
    background: white;
    width: 100%;

    padding: 20px;
`;

export const Title = styled(Typography)`
    font-size: 22px !important;
`;

export const Header = styled.header`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    p {
        font-size: 20px;
        margin: 0;
        font-weight: 700;
    }
`;

export const BackButton = styled.button`
    background: none;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--primary-green);
    margin: 20px 0;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
`;

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 40px;
    background: white;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    gap: 30px;
    margin-top: 30px;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 20px 10px;
    border-radius: 3px;
`;

export const EditButton = styled.button<{ editing?: boolean }>`
    padding: 5px 15px;
    border-radius: 3px;
    background: ${({ editing }) =>
        editing ? "white" : "rgba(18, 18, 18, 0.4)"};
    color: ${({ editing }) => (!editing ? "white" : "rgba(18, 18, 18, 0.4)")};
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.15s;
    font-size: 16px;
    font-weight: 600;

    border: ${({ editing }) =>
        editing ? "1px solid rgba(18, 18, 18, 0.4)" : "1px solid white"};

    :hover {
        border: ${({ editing }) =>
            editing
                ? "1px solid rgba(18, 18, 18, 0.4)"
                : "1px solid rgba(18,18,18,0.8)"};

        background: ${({ editing }) =>
            editing ? "white" : "rgba(18, 18, 18, 0.8)"};
    }
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;

    a {
        text-decoration: none;
        color: black;
        transition: 0.1s;
        &:hover {
            text-decoration: underline;
            color: blue;
        }
    }
`;

export const ListOption = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const AvailabilityContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;

    width: 100%;
    max-width: 500px;
`;

export const AddSection = styled.div`
    gap: 15px;
    display: flex;

    width: 100%;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;

    margin-top: 20px;

    button {
        width: fit-content;
    }
`;

export const SelectContainer = styled.div`
    padding: 20px 10px;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ModalContainer = styled.div`
    background: white;
    padding: 20px;
    width: 400px;
    padding: 30px;

    h1 {
        color: black;
        margin: 0;
    }

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    p {
        margin: 20px 0;
    }

    .buttons {
        display: flex;
        gap: 20px;

        button {
            width: 100px;
            border: none;
            outline: none;
            padding: 10px;
            color: white;
            font-size: 16px;
            font-weight: 700;
            border-radius: 4px;
            cursor: pointer;
        }

        button:first-of-type {
            background: var(--primary-green);
        }
        button:last-of-type {
            background: var(--alert-primary);
        }
    }
`;
