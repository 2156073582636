import React from "react";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import { Container, ModalContainer } from "./teacher.module";
import { deleteTeacher } from "./teacherAPI";
import { getTeachersAsync } from "./teacherSlice";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: "overlay-custom-class-name",
};

export function Teacher() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data, status } = useAppSelector((state) => state.teachers);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    React.useEffect(() => {
        Promise.all([dispatch(getTeachersAsync({ page, rowsPerPage }))]);
    }, [dispatch, page, rowsPerPage]);

    const handleDelete = (id: number) => {
        deleteTeacher(id)
            .then(() => {
                handleToast(false, "Professor deletado com sucesso.");
                return dispatch(getTeachersAsync({ page }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        dispatch(
            getTeachersAsync({
                name: value,
            })
        );
    };

    return (
        <Container className="teacher-container">
            <Table
                columns={[
                    {
                        title: "Professor",
                        key: "name",
                        align: "left",
                    },

                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar professor",
                        onClick: (rowData) => {
                            return navigate(`/professores/${rowData.id}`);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar professor",
                        onClick: (rowData) => {
                            return confirmAlert({
                                ...options,
                                customUI: ({ onClose }) => {
                                    return (
                                        <ModalContainer>
                                            <h1>Aviso</h1>
                                            <p>
                                                Tem certeza que deseja remover
                                                este professor?
                                            </p>
                                            <div className="buttons">
                                                <button
                                                    onClick={() => {
                                                        handleDelete(
                                                            rowData?.id
                                                        );

                                                        onClose();
                                                    }}
                                                >
                                                    Ok
                                                </button>
                                                <button onClick={onClose}>
                                                    Cancelar
                                                </button>
                                            </div>
                                        </ModalContainer>
                                    );
                                },
                            });
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => navigate("/professores/criar")}
                onClickRefresh={() => dispatch(getTeachersAsync({ page }))}
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Professores"
            />
        </Container>
    );
}
