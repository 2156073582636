import axios from "axios";
import { handleToast } from "../components/Toast";
import { store } from "../store/store";
import { clearToken } from "../features/login/tokenSlice";

export const handleError = (error: any) => {
    // crashlytics here

    return Promise.reject(error);
};

const instance = axios.create({
    // baseURL: process.env.REACT_APP_API_URL_DEV,
    // baseURL: "https://orbis-hml.omegacotech.com.br/api",
    baseURL: "https://orbis-dev.omegacotech.com.br/api",
    timeout: 10000,
    timeoutErrorMessage: "Tempo de requisição expirado.",
});

instance.interceptors.request.use(
    async (request) => {
        try {
            if (!request?.url?.endsWith("auth")) {
                const state = store.getState();

                const { data } = state.token;

                request.headers = {
                    ...request.headers,
                    authorization: `Bearer ${data.token}`,
                };
            }

            return request;
        } catch (error: any) {
            // exception to new token here

            return handleError(error);
        }
    },
    (error) => {
        return handleError(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status, data } = error?.response;
        const { dispatch } = store;

        if (status === 400) {
            if (Array.isArray(data.error || data.message))
                data.error.map((error) => handleToast(true, error));

            return handleError(data.error || data.message);
        }

        if (status === 401) {
            dispatch(clearToken());
        }

        if (status === 500) {
            handleToast(true, data.error);

            return handleError(data.error);
        }

        return handleError(error);
    }
);
export default instance;
