import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getClassById, postClass, updateClass } from "../class/classAPI";
import {
    BackButton,
    CalendarSection,
    Calendars,
    Container,
    Content,
    EditButton,
    Form,
    Header,
    LoadingWrapper,
    ConfirmationModal,
    Title,
    CalendarModal,
    CalendarModalWrapper,
    Buttons,
    AddSection,
    List,
    ListOption,
    SelectContainer,
} from "./classEdit.module";
import Loading from "react-loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getShiftsAsync } from "../shift/shiftSlice";
import { getSegmentsAsync } from "../segment/segmentSlice";
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import { confirmAlert } from "react-confirm-alert";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Calendar from "../../components/CalendarView";
import * as dateFns from "date-fns";
import { ptBR } from "date-fns/locale";
import DisciplineHours from "../../components/DisciplineHours";
import CloseIcon from "@mui/icons-material/Close";
import { handleToast } from "../../components/Toast";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { getDisciplinesAsync } from "../discipline/disciplineSlice";

const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: "overlay-custom-class-name",
};

const ClassEdit = () => {
    const { id } = useParams();

    const { data: segmentData } = useAppSelector((state) => state.segments);
    const { data: shiftData } = useAppSelector((state) => state.shifts);
    const { data: disciplineData } = useAppSelector(
        (state) => state.disciplines
    );

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [values, setValues] = useState({
        isActive: false,
        name: "",
    });
    const [edit, setEdit] = useState({ data: false, disciplines: false });

    const [submitting, setSubmitting] = useState(false);

    const [currentCalendar, setCurrentCalendar] = useState(null);

    const [segmentValue, setSegmentValue] = useState({ label: "", value: -1 });
    const [shiftValue, setShiftValue] = useState({ label: "", value: -1 });

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [filteredDisciplines, setFilteredDisciplines] = useState([]);
    const [disciplineSearch, setDisciplineSearch] = useState("");
    const [disciplineValue, setDisciplineValue] = useState({
        label: "",
        value: -1,
    });

    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [teacherSearch, setTeacherSearch] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            if (data) {
                const { isActive, name, segmentId, shiftId, segment, shift } =
                    data;

                setValues({ isActive, name });

                setSegmentValue({ label: segment, value: segmentId });
                setShiftValue({ label: shift, value: shiftId });
            }

            if (data?.disciplines?.length) {
                setFilteredDisciplines(data?.disciplines);
            }

            if (data?.teachers?.length) {
                setFilteredTeachers(data?.teachers);
            }

            await dispatch(getDisciplinesAsync({ page: -1 }));
        };

        fetchData();
    }, [data, dispatch]);

    const listTeachers = useMemo(() => {
        return filteredTeachers.filter((item) =>
            item?.name
                ?.toLocaleUpperCase()
                ?.includes(teacherSearch?.toLocaleUpperCase())
        );
    }, [filteredTeachers, teacherSearch]);

    const disciplineOptions = useMemo(() => {
        if (disciplineData?.length) {
            return disciplineData.map((discipline) => ({
                label: discipline.name,
                value: discipline.id,
            }));
        }

        return [];
    }, [disciplineData]);

    const removeDiscipline = useCallback(
        (id: number) => {
            if (!filteredDisciplines?.length) return;

            setFilteredDisciplines(
                filteredDisciplines.filter((c) => c.id !== id)
            );
            setShowModal(true);
        },
        [filteredDisciplines]
    );

    const addDiscipline = useCallback(
        (id: number) => {
            if (!id) return;
            const foundDiscipline = filteredDisciplines.find(
                (c) => c?.id === id
            );
            if (foundDiscipline) return;

            const disciplineElement = disciplineData?.find((c) => {
                return c?.id === id;
            });

            setFilteredDisciplines([...filteredDisciplines, disciplineElement]);
            setShowModal(true);
        },
        [disciplineData, filteredDisciplines]
    );

    const listDisciplines = useMemo(() => {
        return filteredDisciplines.filter((item) =>
            item?.name
                ?.toLocaleUpperCase()
                ?.includes(disciplineSearch?.toLocaleUpperCase())
        );
    }, [filteredDisciplines, disciplineSearch]);

    const onSubmit = useCallback(async () => {
        setSubmitting(true);

        if (shiftValue.value === -1) {
            return handleToast(true, "Escolha um turno.");
        }

        if (segmentValue.value === -1) {
            return handleToast(true, "Escolha um segmento.");
        }

        const obj = {
            ...values,
            shiftId: shiftValue.value,
            segmentId: segmentValue.value,
        };

        try {
            if (id) {
                const removedDisciplines = data?.disciplines
                    .filter(
                        (disciplines) =>
                            !filteredDisciplines
                                .map((c) => c.id)
                                .includes(disciplines.id)
                    )
                    .map((item) => item.id);

                const addedDisciplines = filteredDisciplines
                    .filter(
                        (disciplines) =>
                            !data?.disciplines
                                .map((c) => c.id)
                                .includes(disciplines.id)
                    )
                    .map((item) => item.id);

                await updateClass({
                    ...obj,
                    id,
                    addedDisciplines,
                    removedDisciplines,
                });
                navigate(0);
            } else {
                const res: any = await postClass({
                    ...obj,
                    disciplines: filteredDisciplines,
                });

                navigate("/turmas/" + res?.data?.data?.id);
            }
        } catch (e) {
            handleToast(true, "Não foi possível editar a turma.");
        } finally {
            setSubmitting(false);
        }
    }, [
        id,
        values,
        segmentValue,
        shiftValue,
        navigate,
        data?.disciplines,
        filteredDisciplines,
    ]);

    const formatDate = useCallback((date: string) => {
        if (!date) return date;

        const [year, month, day] = date.split("-");

        return `${day}/${month}/${year}`;
    }, []);

    const renderCalendar = useCallback(() => {
        const initialDate = new Date(currentCalendar.startDate);

        const timeZonedDate = dateFns.addHours(initialDate, 3);

        const currDate = dateFns.addMonths(timeZonedDate, 0);
        const currMonth = dateFns.format(currDate, "MMMM", {
            locale: ptBR,
        });

        const passedDate = dateFns.isSameMonth(currDate, initialDate)
            ? initialDate
            : dateFns.startOfMonth(currDate);

        if (currentCalendar) {
            return (
                <>
                    <DisciplineHours
                        month={currentCalendar.month}
                        disciplineHours={
                            currentCalendar?.calendar?.disciplineHours
                        }
                    />
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Calendar
                            type={"monthly"}
                            data={currentCalendar?.calendar?.calendar}
                            startTime={"6:00"}
                            key={currMonth}
                            startDate={passedDate}
                            endDate={new Date(currentCalendar.endDate)}
                            currentDate={currDate}
                            module={data?.segment as string}
                            shift={data?.shift as string}
                            // periods={periods?.data}
                            month={currMonth}
                            classId={data?.id}
                            segmentId={data?.segmentId}
                            shiftId={data?.shiftId}
                            disciplineHours={
                                currentCalendar?.calendar?.disciplineHours
                            }
                            className={data?.name}
                        />
                    </Box>
                </>
            );
        }

        return null;
    }, [data, currentCalendar]);

    const handleChange = useCallback(
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });

            setShowModal(true);
        },
        [values]
    );

    useEffect(() => {
        const fetch = async () => {
            try {
                dispatch(getShiftsAsync({ page: -1 }));
                dispatch(getSegmentsAsync({ page: -1 }));
                if (id) {
                    const res: any = await getClassById(Number(id));

                    setData(res?.data?.data);
                }
            } catch (e) {
                handleToast(true, "Ocorreu um erro ao trazer dados.");
                navigate("/turmas");
            } finally {
                setLoading(false);
            }
        };

        fetch();
    }, [id, navigate, dispatch]);

    const segmentOptions = useMemo(() => {
        if (segmentData?.length) {
            return segmentData?.map((segment) => ({
                label: `${segment.name}`,
                value: segment.id,
            }));
        }

        return [];
    }, [segmentData]);

    const shiftOptions = useMemo(() => {
        if (shiftData?.length) {
            return shiftData?.map((shift) => ({
                label: `${shift.name}`,
                value: shift.id,
            }));
        }

        return [];
    }, [shiftData]);

    if (loading) {
        return (
            <LoadingWrapper>
                <Loading
                    type="spinningBubbles"
                    color={"rgb(9, 160, 17)"}
                    width={30}
                    height={30}
                />
            </LoadingWrapper>
        );
    }

    return (
        <Container>
            <BackButton
                onClick={() => {
                    showModal
                        ? confirmAlert({
                              ...options,
                              customUI: ({ onClose }) => {
                                  return (
                                      <ConfirmationModal>
                                          <h1>Aviso</h1>
                                          <p>
                                              Todos os dados não salvos serão
                                              apagados.
                                          </p>
                                          <div className="buttons">
                                              <button
                                                  onClick={() => {
                                                      navigate("/turmas");
                                                      onClose();
                                                  }}
                                              >
                                                  Ok
                                              </button>
                                              <button onClick={onClose}>
                                                  Cancelar
                                              </button>
                                          </div>
                                      </ConfirmationModal>
                                  );
                              },
                          })
                        : navigate("/turmas");
                }}
            >
                <ArrowBackIosNewIcon fontSize="small" /> Voltar
            </BackButton>
            <Title>{data?.name}</Title>
            <Content>
                <Form>
                    <Header>
                        <p>Informações</p>
                        {!!id && (
                            <EditButton
                                type="button"
                                editing={edit.data}
                                onClick={() =>
                                    setEdit({ ...edit, data: !edit.data })
                                }
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>
                    <TextField
                        label="Nome"
                        size="small"
                        value={values?.name}
                        onChange={handleChange("name")}
                        fullWidth
                        disabled={!edit.data && !!id}
                    />

                    <Autocomplete
                        options={segmentOptions}
                        value={segmentValue}
                        fullWidth
                        onChange={(e, v) => setSegmentValue(v)}
                        size="small"
                        disabled={!edit.data && !!id}
                        renderInput={(params) => (
                            <TextField label={"Segmento"} {...params} />
                        )}
                    />
                    <Autocomplete
                        options={shiftOptions}
                        value={shiftValue}
                        fullWidth
                        onChange={(e, v) => setShiftValue(v)}
                        size="small"
                        disabled={!edit.data && !!id}
                        renderInput={(params) => (
                            <TextField label={"Turno"} {...params} />
                        )}
                    />
                    <FormControlLabel
                        disabled={!edit.data && !!id}
                        control={
                            <Switch
                                size="small"
                                checked={values?.isActive}
                                onChange={() =>
                                    setValues((prevState) => ({
                                        ...prevState,
                                        isActive: !prevState.isActive,
                                    }))
                                }
                                inputProps={{ "aria-label": "isActive" }}
                            />
                        }
                        label="Ativo"
                    />
                </Form>

                <SelectContainer>
                    <Header>
                        <p>Disciplinas</p>
                        {!!id && (
                            <EditButton
                                editing={edit.disciplines}
                                type="button"
                                onClick={() =>
                                    setEdit({
                                        ...edit,
                                        disciplines: !edit.disciplines,
                                    })
                                }
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>

                    <AddSection>
                        {edit.disciplines ? (
                            <Autocomplete
                                options={disciplineOptions}
                                value={disciplineValue}
                                onChange={(e, v) => setDisciplineValue(v)}
                                size="small"
                                fullWidth
                                disabled={!edit.disciplines}
                                renderInput={(params) => (
                                    <TextField
                                        label={"Disciplina"}
                                        {...params}
                                    />
                                )}
                            />
                        ) : (
                            <TextField
                                label="Pesquisar disciplina"
                                size="small"
                                value={disciplineSearch}
                                onChange={(e) =>
                                    setDisciplineSearch(e.target.value)
                                }
                                fullWidth
                            />
                        )}
                        <Button
                            disabled={!edit.disciplines}
                            variant="contained"
                            onClick={() => {
                                addDiscipline(disciplineValue.value);
                                setDisciplineValue({
                                    label: "",
                                    value: -1,
                                });
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </AddSection>
                    <List>
                        {listDisciplines?.map((c) => (
                            <ListOption key={c.id}>
                                <Link to={`/disciplinas/${c.id}`}>
                                    {c.discipline || c.name}
                                </Link>
                                {edit.disciplines && (
                                    <Tooltip title={"Remover disciplina"}>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                removeDiscipline(c.id)
                                            }
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
                {!!listTeachers?.length && (
                    <SelectContainer>
                        <Header>
                            <p>Professores</p>
                        </Header>

                        <AddSection>
                            <TextField
                                label="Pesquisar professor"
                                size="small"
                                value={teacherSearch}
                                onChange={(e) =>
                                    setTeacherSearch(e.target.value)
                                }
                                fullWidth
                            />
                        </AddSection>
                        <List>
                            {listTeachers?.map((c) => (
                                <ListOption key={c.id}>
                                    <Link to={`/professores/${c.id}`}>
                                        {c.name}
                                    </Link>
                                </ListOption>
                            ))}
                        </List>
                    </SelectContainer>
                )}
            </Content>
            {!!data?.calendars?.length && id && (
                <Content>
                    <Header>
                        <p>Calendários</p>
                    </Header>
                    <Calendars>
                        {data?.calendars?.map((calendar) => (
                            <CalendarSection
                                onClick={() => {
                                    setCurrentCalendar(calendar);
                                }}
                            >
                                <p>
                                    <span>Mês: </span>
                                    {calendar?.month}
                                </p>
                                <p>
                                    <span>Início: </span>
                                    {formatDate(calendar?.startDate)}
                                </p>
                                <p>
                                    <span>Fim: </span>
                                    {formatDate(calendar?.endDate)}
                                </p>
                            </CalendarSection>
                        ))}
                    </Calendars>
                    {currentCalendar && (
                        <CalendarModalWrapper>
                            <CalendarModal>
                                <button
                                    onClick={() => {
                                        setCurrentCalendar(null);
                                    }}
                                >
                                    <CloseIcon fontSize="large" />
                                </button>
                                <h1>Calendário</h1>
                                {renderCalendar()}
                            </CalendarModal>
                        </CalendarModalWrapper>
                    )}
                </Content>
            )}
            {(edit.data || edit.disciplines || !id) && (
                <Buttons>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={submitting}
                    >
                        Enviar
                    </Button>
                </Buttons>
            )}
        </Container>
    );
};

export default ClassEdit;
