import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section``;

export const Wrapper = styled.section`
    background-color: #ffffff;
`;

export const DrawerContainer = styled.section`
    padding: 20px 15px;
    width: 400px;
`;

export const ModalContainer = styled.div`
    background: white;
    padding: 20px;
    width: 400px;
    padding: 30px;

    h1 {
        color: black;
        margin: 0;
    }

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    p {
        margin: 20px 0;
    }

    .buttons {
        display: flex;
        gap: 20px;

        button {
            width: 100px;
            border: none;
            outline: none;
            padding: 10px;
            color: white;
            font-size: 16px;
            font-weight: 700;
            border-radius: 4px;
            cursor: pointer;
        }

        button:first-of-type {
            background: var(--primary-green);
        }
        button:last-of-type {
            background: var(--alert-primary);
        }
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    gap: 1rem;
`;

export const Row = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    gap: 5px;
`;

export const DrawerTitle = styled(Typography)`
    font-size: 22px !important;
`;

export const Label = styled(Typography)`
    flex: 1;
    font-size: 18px !important;
`;

export const DrawerSubtitle = styled(Typography)`
    font-size: 18px !important;
    color: #cecece;
`;

export const LoadingWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`;
