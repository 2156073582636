import React, { useCallback, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
    Box,
    Button,
    MenuItem,
    Typography,
    Tabs,
    Tab,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    List,
    Divider,
    Modal,
    Stack,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import * as dateFns from "date-fns";
import { ptBR } from "date-fns/esm/locale";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Calendar } from "../../components";
import {
    Section,
    Container,
    InputField,
    SideSection,
    PaginationContainer,
    SideContainer,
    ListDiv,
} from "./home.module";
import { getClassSchedulesAsync, storeHome } from "./homeSlice";
import { getSegmentsAsync } from "../segment/segmentSlice";
import { getShiftsAsync } from "../shift/shiftSlice";
import { handleToast } from "../../components/Toast";
import { getPeriodsByShiftAsync } from "../period/periodSlice";
import TeacherList from "../../components/TeacherList";
import Pagination from "../../components/Pagination";
import { getClassesAsync } from "../class/classSlice";
import { LoadingWrapper } from "../class/class.module";
import Loading from "react-loading";
import { getCalendarSaveAsync } from "../../components/Calendar/indexSlice";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DisciplineHours from "../../components/DisciplineHours";
import MuiPagination from "@mui/material/Pagination";
import { useDebounce } from "use-debounce";
import { Delete } from "@mui/icons-material";
import { deleteClassSchedules } from "./homeAPI";
import { useNavigate } from "react-router-dom";

interface IPeriods {
    start: Date;
    end: Date;
}

export function Home() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const segments = useAppSelector((state) => state.segments);
    const shifts = useAppSelector((state) => state.shifts);
    const home = useAppSelector((state) => state.home);
    const classes = useAppSelector((state) => state.class);
    const periods = useAppSelector((state) => state.periods);
    const calendarSave: any = useAppSelector((state) => state.calendarSave);
    const [editCalendarDate, setEditCalendarDate] = useState({
        startDate: "",
        endDate: "",
    });

    const [page, setPage] = useState<number>(0);
    const [searchValue, setSearchValue] = useState("");
    const [fulltext] = useDebounce(searchValue, 1000);

    const [values, setValues] = useState<IPeriods>({
        start: null,
        end: null,
    });
    const [tabValue, setTabValue] = useState(0);
    const [segment, setSegment] = useState(null),
        [shift, setShift] = useState(null),
        [currentClass, setCurrentClass] = useState(null),
        [months, setMonths] = useState<number>(0),
        [currentPage, setCurrentPage] = useState<number>(1),
        [disableCalendarSave] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [deleteCalendar, setDeleteCalendar] = useState<number>();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [weeklyCalendarCheck, setWeeklyCalendarCheck] = useState(false);

    const handleChange = (event) => {
        setWeeklyCalendarCheck(event.target.checked);
    };

    React.useEffect(() => {
        Promise.allSettled([
            dispatch(getSegmentsAsync({ page: -1 })),
            dispatch(getShiftsAsync({ page: -1 })),
        ]);
    }, [dispatch]);

    React.useEffect(() => {
        const fetch = async () => {
            if (shift && segment) {
                await dispatch(
                    getClassesAsync({
                        page: -1,
                        segmentId: segment,
                        shiftId: shift,
                    })
                );
            }
        };

        fetch();
    }, [shift, segment, dispatch]);

    const getPeriods = React.useCallback(async () => {
        if (shift) {
            dispatch(getPeriodsByShiftAsync(shift));
        }
    }, [dispatch, shift]);

    const getCalendarSave = React.useCallback(async () => {
        dispatch(getCalendarSaveAsync({ page, fulltext }));
    }, [dispatch, page, fulltext]);

    React.useEffect(() => {
        getPeriods();
        getCalendarSave();
    }, [getPeriods, getCalendarSave, fulltext]);

    React.useEffect(() => {
        if (editCalendarDate.startDate && editCalendarDate.endDate) {
            const diff =
                dateFns.differenceInMonths(
                    new Date(editCalendarDate.endDate),
                    new Date(editCalendarDate.startDate)
                ) + 1;
            setMonths(diff);
        } else {
            if (values?.start && values?.end) {
                const diff =
                    dateFns.differenceInMonths(values?.end, values?.start) + 1;
                setMonths(diff);
            }
        }
    }, [values?.start, values?.end, editCalendarDate]);

    const shiftName = React.useMemo(() => {
        let name = "";
        shifts?.data?.forEach((item) => {
            if (item?.id === shift) name = item?.name;
        });
        return name;
    }, [shift, shifts?.data]);

    const segmentName = React.useMemo(() => {
        let name = "";
        segments?.data?.forEach((item) => {
            if (item?.id === shift) name = item?.name;
        });
        return name;
    }, [segments?.data, shift]);

    React.useEffect(() => {
        if (values?.end) {
            if (moment(values?.end) <= moment(values?.start)) {
                handleToast(
                    true,
                    "Data final deve ser maior que a data inicial do período"
                );
                setValues({
                    ...values,
                    end: null,
                });
                return;
            }
        }
    }, [values, values?.end]);

    const handleChangeSegment = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSegment(event.target.value);
        },
        []
    );

    const handleChangeShift = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setShift(event.target.value);
        },
        []
    );

    const handleChangeClass = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentClass(event.target.value);
        },
        []
    );
    const onDeleteCalendar = useCallback(() => {
        if (!deleteCalendar) return;
        deleteClassSchedules(deleteCalendar);
        handleClose();
        setTimeout(() => {
            navigate(0);
        }, 1000);
    }, [deleteCalendar, navigate]);

    const renderCalendar = () => {
        const data = [];

        if (!months) return null;

        const initialDate = editCalendarDate.startDate
            ? new Date(editCalendarDate.startDate)
            : values.start;

        const timeZonedDate = dateFns.addHours(initialDate, 3);

        const currDate = dateFns.addMonths(timeZonedDate, currentPage - 1);
        const currMonth = dateFns.format(currDate, "MMMM", {
            locale: ptBR,
        });

        const passedDate = dateFns.isSameMonth(currDate, initialDate)
            ? initialDate
            : dateFns.startOfMonth(currDate);

        if (home.data) {
            const currentMonthCalendar = home?.data?.result?.find(
                (item) => item.month === currMonth
            );

            if (editCalendarDate.startDate && editCalendarDate.endDate) {
                data.push(
                    <Calendar
                        type={"monthly"}
                        data={currentMonthCalendar}
                        startTime={"6:00"}
                        key={currMonth}
                        startDate={passedDate}
                        endDate={new Date(editCalendarDate.endDate)}
                        currentDate={currDate}
                        module={segmentName}
                        shift={home?.data?.shift}
                        periods={periods?.data}
                        month={currMonth}
                        classId={home?.data?.classId}
                        segmentId={home?.data?.segmentId}
                        shiftId={home?.data?.shiftId}
                        disciplineHours={home?.data?.disciplineHours}
                        className={home?.data?.class}
                    />
                );
            } else {
                data.push(
                    <Calendar
                        type={"monthly"}
                        data={currentMonthCalendar}
                        startTime={"6:00"}
                        key={currMonth}
                        startDate={passedDate}
                        endDate={values.end}
                        currentDate={currDate}
                        module={segmentName}
                        shift={shiftName}
                        periods={periods?.data}
                        month={currMonth}
                        classId={currentClass}
                        segmentId={segment}
                        shiftId={shift}
                        disciplineHours={home?.data?.disciplineHours}
                        className={
                            classes?.data?.find(
                                (item) => item?.id === currentClass
                            )?.name || ""
                        }
                    />
                );
            }
        }

        if (data.length) {
            return (
                <>
                    <Pagination
                        currPage={currentPage}
                        next={() => setCurrentPage(currentPage + 1)}
                        prev={() => setCurrentPage(currentPage - 1)}
                        title={currMonth}
                        lastPage={Math.min(
                            Object.keys(home.data.result).length,
                            months
                        )}
                        firstPage={1}
                    />
                    <DisciplineHours
                        month={currMonth}
                        disciplineHours={home?.data?.disciplineHours}
                    />
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                p: 3,
                                width: { sm: `calc(100% - ${240}px)` },
                            }}
                        >
                            {data}
                        </Box>
                    </Box>
                </>
            );
        }

        return null;
    };

    React.useEffect(() => {
        setCurrentPage(1);
    }, []);

    const handleGetClassSchedules = async () => {
        let startDate: string;
        let endtDate: string;

        setEditCalendarDate({ startDate: "", endDate: "" });

        if (!shift) {
            return handleToast(true, "Selecione um turno");
        }
        if (!segment) {
            return handleToast(true, "Selecione um módulo ou segmento");
        }
        if (!classes) return handleToast(true, "Selecione uma turma");

        if (!values.start || !values.end)
            return handleToast(true, "Informe o período desejado");

        if (values.end && values.start) {
            startDate = values.start.toISOString().slice(0, 10);
            endtDate = values.end.toISOString().slice(0, 10);
        }
        try {
            await dispatch(
                getClassSchedulesAsync({
                    params: {
                        shiftId: shift,
                        segmentId: segment,
                        classId: currentClass,
                        periodStart: startDate,
                        periodEnd: endtDate,
                        weeklyCalendar: weeklyCalendarCheck,
                    },
                    months,
                    values,
                    periods,
                })
            );
        } catch (error) {
            handleToast(true, error);
        }
    };

    return (
        <Container className="daschboard-container">
            <SideContainer>
                <SideSection>
                    <Tabs
                        value={tabValue}
                        centered
                        onChange={(e, newValue) => setTabValue(newValue)}
                    >
                        <Tab label="Criar" value={0} />
                        <Tab label="Salvos" value={1} />
                    </Tabs>

                    {tabValue === 0 && (
                        <>
                            <Typography variant="h6">Filtros</Typography>
                            <InputField
                                select
                                label="Segmento"
                                size="small"
                                value={segment}
                                onChange={handleChangeSegment}
                            >
                                {segments?.data?.map((item) => (
                                    <MenuItem key={item?.id} value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                            </InputField>
                            <InputField
                                select
                                label="Turno"
                                size="small"
                                value={shift}
                                onChange={handleChangeShift}
                                disabled={!segment}
                            >
                                {shifts?.data?.map((item) => (
                                    <MenuItem key={item?.id} value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                            </InputField>
                            <InputField
                                select
                                label="Turma"
                                size="small"
                                value={currentClass}
                                onChange={handleChangeClass}
                                disabled={
                                    (!segment || !shift) && !classes?.loading
                                }
                                SelectProps={{
                                    displayEmpty: false,
                                }}
                            >
                                {classes.loading ? (
                                    <LoadingWrapper
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Loading
                                            type="spinningBubbles"
                                            color={"rgb(9, 160, 17)"}
                                            width={30}
                                            height={30}
                                        />
                                    </LoadingWrapper>
                                ) : (
                                    classes?.data?.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item?.id}
                                                value={item?.id}
                                            >
                                                {item?.className ||
                                                    item?.name ||
                                                    "Nenhum"}
                                            </MenuItem>
                                        );
                                    })
                                )}
                            </InputField>
                            <DatePicker
                                label="Início do período"
                                value={values?.start}
                                onChange={(newValue) => {
                                    setValues({
                                        ...values,
                                        start: newValue,
                                    });
                                }}
                                renderInput={(params) => (
                                    <InputField size="small" {...params} />
                                )}
                            />
                            <DatePicker
                                label="Fim do período"
                                value={values?.end}
                                onChange={(newValue) => {
                                    setValues({
                                        ...values,
                                        end: newValue,
                                    });
                                }}
                                renderInput={(params) => (
                                    <InputField size="small" {...params} />
                                )}
                                disabled={values?.start === null}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={weeklyCalendarCheck}
                                        onChange={handleChange}
                                    />
                                }
                                label="Criar calendário semanal"
                            />
                            <Button onClick={handleGetClassSchedules}>
                                Criar Calendário
                            </Button>
                        </>
                    )}
                    {tabValue === 1 && (
                        <>
                            {calendarSave?.status === "loading" && (
                                <LoadingWrapper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Loading
                                        type="spinningBubbles"
                                        color={"rgb(9, 160, 17)"}
                                        width={30}
                                        height={30}
                                    />
                                </LoadingWrapper>
                            )}
                            {calendarSave?.status === "idle" && (
                                <>
                                    <Typography variant="h6">
                                        Calendários Salvos
                                    </Typography>
                                    <InputField
                                        variant="filled"
                                        size="small"
                                        placeholder="Buscar..."
                                        onChange={(e) =>
                                            setSearchValue(e.target.value)
                                        }
                                    />
                                    <ListDiv>
                                        <List>
                                            {calendarSave?.data?.items?.map(
                                                (calendar) => (
                                                    <>
                                                        <ListItem
                                                            key={calendar.id}
                                                        >
                                                            <ListItemText
                                                                primary={`Turma: ${calendar.class}`}
                                                                secondary={`Segmento: ${calendar.segment}, Turno: ${calendar.shift}, Mês: ${calendar.month}`}
                                                            />
                                                            <ListItemSecondaryAction
                                                                sx={{
                                                                    width: "90px",
                                                                    right: "0",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            storeHome(
                                                                                calendar
                                                                            )
                                                                        );
                                                                        setEditCalendarDate(
                                                                            {
                                                                                startDate:
                                                                                    calendar.startDate ||
                                                                                    "",
                                                                                endDate:
                                                                                    calendar.endDate ||
                                                                                    "",
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <ModeEditIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => {
                                                                        setDeleteCalendar(
                                                                            calendar.id
                                                                        );
                                                                        handleOpen();
                                                                    }}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider
                                                            variant="inset"
                                                            component="li"
                                                        />
                                                    </>
                                                )
                                            )}
                                        </List>
                                    </ListDiv>
                                    <PaginationContainer>
                                        <MuiPagination
                                            count={10}
                                            page={page}
                                            onChange={(e, page) =>
                                                setPage(page)
                                            }
                                        />
                                    </PaginationContainer>
                                </>
                            )}
                        </>
                    )}
                </SideSection>
                {home?.data && (
                    <SideSection>
                        <TeacherList
                            calendar={home?.data?.result}
                            classId={home?.data?.classId || currentClass}
                        />
                    </SideSection>
                )}
            </SideContainer>
            <Section>
                <Typography variant="h6">Grades de Aulas</Typography>
                {calendarSave?.status === "loading" && (
                    <LoadingWrapper
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Loading
                            type="spinningBubbles"
                            color={"rgb(9, 160, 17)"}
                            width={30}
                            height={30}
                        />
                    </LoadingWrapper>
                )}
                {home.status === "idle" &&
                    !disableCalendarSave &&
                    renderCalendar()}
            </Section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "1px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Essa ação não pode ser revertida
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Deseja deletar o calendário ?
                    </Typography>

                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        width={"13rem"}
                    >
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onDeleteCalendar}
                        >
                            Deletar
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </Container>
    );
}
