import { Routes, Route, useNavigate } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBr from "date-fns/locale/pt-BR";

import { MainContainer } from "./components/Containers";
import { Home } from "./features/home/Home";
import { Discipline } from "./features/discipline/Discipline";
import { Teacher } from "./features/teacher/Teacher";
import { Settings } from "./features/settings/Settings";
import { Class } from "./features/class/Class";
import Login from "./features/login/Login";
import { theme } from "./app/theme";

import Orion from "./assets/images/Orion_Debruçado_(sem_logo).webp";
import GlobalStyles from "./styles/global";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import { useAppSelector } from "./app/hooks";
import { tokenSelector } from "./features/login/tokenSlice";
import ProtectedRoute from "./components/ProtectedRoute";
import LogoutButton from "./components/LogoutButton";
import React from "react";
import DisciplineEdit from "./features/disciplineEdit/DisciplineEdit";
import TeacherEdit from "./features/teacherEdit/TeacherEdit";
import ClassEdit from "./features/classEdit/ClassEdit";

interface Props {
    window?: () => Window;
}

function App(props: Props) {
    const route = useNavigate();
    const token = useAppSelector(tokenSelector);
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const hiddenPages = React.useMemo(
        () => [
            "Criar disciplina",
            "Editar disciplina",
            "Criar professor",
            "Editar professor",
            "Login",
            "Editar turma",
            "Criar turma",
        ],
        []
    );

    const pages = [
        {
            name: "Calendários",
            route: "/",
            element: <Home />,
            hidden: !token,
        },
        {
            name: "Disciplinas",
            route: "/disciplinas",
            element: <Discipline />,
            hidden: !token,
        },
        {
            name: "Turmas",
            route: "/turmas",
            element: <Class />,
            hidden: !token,
        },
        {
            name: "Professores",
            route: "/professores",
            element: <Teacher />,
            hidden: !token,
        },
        {
            name: "Configurações",
            route: "/configuracoes",
            element: <Settings />,
            hidden: !token,
        },
        {
            name: "Editar disciplina",
            route: "/disciplinas/:id",
            element: <DisciplineEdit />,
            hidden: !token,
        },
        {
            name: "Editar professor",
            route: "/professores/:id",
            element: <TeacherEdit />,
            hidden: !token,
        },
        {
            name: "Criar disciplina",
            route: "/disciplinas/criar",
            element: <DisciplineEdit />,
            hidden: !token,
        },
        {
            name: "Criar professor",
            route: "/professores/criar",
            element: <TeacherEdit />,
            hidden: !token,
        },
        {
            name: "Criar turma",
            route: "/turmas/criar",
            element: <ClassEdit />,
            hidden: !token,
        },
        {
            name: "Editar turma",
            route: "/turmas/:id",
            element: <ClassEdit />,
            hidden: !token,
        },
        {
            name: "Login",
            route: "/login",
            element: <Login />,
            hidden: !!token,
        },
    ];

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleNavigate = React.useCallback(
        (routes: string) => {
            return route(routes);
        },
        [route]
    );

    const drawer = (
        <List>
            {pages.map((item, index) => (
                <>
                    {item.name !== "Login" ? (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                onClick={() => handleNavigate(item.route)}
                                sx={{ textAlign: "center" }}
                            >
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    ) : (
                        <LogoutButton />
                    )}
                </>
            ))}
        </List>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBr}>
            <DndProvider backend={HTML5Backend}>
                <ThemeProvider theme={theme}>
                    {!!token && (
                        <header>
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2, display: { sm: "none" } }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <img
                                        src={Orion}
                                        alt="Orion.png"
                                        style={{
                                            width: "50px",
                                            marginRight: 10,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: {
                                                xs: "none",
                                                sm: "block",
                                            },
                                        }}
                                    >
                                        {pages.map((item, index) => {
                                            if (item.name === "Login") {
                                                return <LogoutButton />;
                                            }

                                            if (hiddenPages.includes(item.name))
                                                return null;

                                            return (
                                                <Button
                                                    key={index}
                                                    onClick={() =>
                                                        handleNavigate(
                                                            item.route
                                                        )
                                                    }
                                                    sx={{
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {item.name}
                                                </Button>
                                            );
                                        })}
                                    </Box>
                                </Toolbar>
                            </AppBar>
                            <Box component="nav">
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: "block", sm: "none" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: 300,
                                        },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Box>
                        </header>
                    )}
                    <MainContainer className="main-container">
                        <Routes>
                            {pages?.map((item, index) => (
                                <Route
                                    key={index}
                                    path={item?.route}
                                    element={
                                        <ProtectedRoute
                                            element={item?.element}
                                            isLogin={item?.name === "Login"}
                                        />
                                    }
                                />
                            ))}
                        </Routes>
                    </MainContainer>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <GlobalStyles />
                </ThemeProvider>
            </DndProvider>
        </LocalizationProvider>
    );
}

export default App;
