import React from "react";

import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import { Container } from "./class.module";
import { deleteClass } from "./classAPI";
import { getClassesAsync } from "./classSlice";
import { useNavigate } from "react-router-dom";

export function Class() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data, status, loading } = useAppSelector((state) => state.class);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    React.useEffect(() => {
        Promise.all([dispatch(getClassesAsync({ page, rowsPerPage }))]);
    }, [dispatch, page, rowsPerPage]);

    const handleDelete = (id: number) => {
        deleteClass({
            id: id,
            isActive: false,
        })
            .then(() => {
                handleToast(false, "Disciplina deletada com sucesso.");
                return dispatch(getClassesAsync({ page }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            getClassesAsync({
                name: event.target.value,
            })
        );
    };

    return (
        <Container className="daschboard-container">
            <Table
                columns={[
                    {
                        title: "Turma",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Segmento",
                        key: "segmentName",
                        align: "left",
                    },
                    {
                        title: "Turno",
                        key: "shiftName",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar turma",
                        onClick: (rowData) => {
                            navigate(`/turmas/${rowData.id}`);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar turma",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => navigate("/turmas/criar")}
                onClickRefresh={() =>
                    dispatch(getClassesAsync({ page, rowsPerPage }))
                }
                handleChange={handleSearch}
                status={status}
                loading={loading}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Turmas"
            />
        </Container>
    );
}
