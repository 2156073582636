import { useCallback, useEffect, useMemo, useState } from "react";
import {
    AddSection,
    AvailabilityContainer,
    BackButton,
    Buttons,
    Container,
    Content,
    EditButton,
    Form,
    Header,
    List,
    ListOption,
    LoadingWrapper,
    ModalContainer,
    SelectContainer,
} from "./teacherEdit.module";
import {
    getTeacherById,
    postTeacher,
    updateTeacher,
} from "../teacher/teacherAPI";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "react-loading";
import {
    Autocomplete,
    Button,
    FormControlLabel,
    IconButton,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDisciplinesAsync } from "../discipline/disciplineSlice";
import { confirmAlert } from "react-confirm-alert";
import { Stack } from "@mui/system";
import { DaysPicker } from "./AvaliabilityContainer";
import { getPeriods } from "../period/periodApi";

const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: "overlay-custom-class-name",
};
interface IPeriod {
    id?: number;
    start: string;
    end: string;
    shiftId?: number;
    isActive: boolean;
}

const TeacherEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: disciplineData } = useAppSelector(
        (state) => state.disciplines
    );
    const dispatch = useAppDispatch();

    const [data, setData] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({ name: "", isActive: false });
    const [edit, setEdit] = useState({
        data: !id,
        disciplines: !id,
        availabilities: !id,
    });
    const [submitting, setSubimitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [iperiods, setIperiods] = useState<IPeriod[]>();

    const [availabilities, setAvailabilities] = useState([]);
    const [newAvailabilities, setNewAvailabilities] = useState([]);
    const [removedAvailabilities, setRemovedAvailabilities] = useState([]);
    const [changedAvailabilities, setChangedAvailabilities] = useState([]);
    const [removedAvaliabilities, setRemoveAvaliabilities] = useState([]);

    const [filteredDisciplines, setFilteredDisciplines] = useState([]);
    const [disciplineSearch, setDisciplineSearch] = useState("");
    const [disciplineValue, setDisciplineValue] = useState({
        label: "",
        value: -1,
    });

    const [filteredClasses, setFilteredClasses] = useState([]);
    const [classSearch, setClassSearch] = useState("");

    const disciplineOptions = useMemo(() => {
        if (disciplineData?.length) {
            return disciplineData.map((discipline) => ({
                label: discipline.name,
                value: discipline.id,
            }));
        }

        return [];
    }, [disciplineData]);

    const listClasses = useMemo(() => {
        if (filteredClasses?.length) {
            return filteredClasses.filter((item) =>
                item?.name
                    ?.toLocaleUpperCase()
                    ?.includes(classSearch?.toLocaleUpperCase())
            );
        }
    }, [classSearch, filteredClasses]);

    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const res = await getTeacherById(id);
                setData(res?.data);
            }
            const periodRes = await getPeriods();
            setIperiods(periodRes.data);
            setLoading(false);
        };
        dispatch(getDisciplinesAsync({ page: -1 }));

        fetch();
    }, [id, dispatch]);

    useEffect(() => {
        if (data?.teacherAvailabilities?.length) {
            setAvailabilities(data?.teacherAvailabilities);
        }
        if (data?.disciplines?.length) {
            setFilteredDisciplines(data?.disciplines);
        }
        if (data?.classes?.length) {
            setFilteredClasses(data?.classes);
        }

        setValues({
            name: data?.name,
            isActive: data?.isActive,
        });
    }, [data]);

    const onChangeNewAvailability = useCallback(
        (index: number, key: string, value: any) => {
            const temp = [...newAvailabilities];
            temp[index][key] = value;

            setNewAvailabilities(temp);
            setShowModal(true);
        },
        [newAvailabilities]
    );

    const removeDiscipline = useCallback(
        (id: number) => {
            if (!filteredDisciplines?.length) return;

            setFilteredDisciplines(
                filteredDisciplines.filter((c) => c.id !== id)
            );
            setShowModal(true);
        },
        [filteredDisciplines]
    );

    const addDiscipline = useCallback(
        (id: number) => {
            if (!id) return;
            const foundDiscipline = filteredDisciplines.find(
                (c) => c?.id === id
            );
            if (foundDiscipline) return;

            const disciplineElement = disciplineData?.find((c) => {
                return c?.id === id;
            });

            setFilteredDisciplines([...filteredDisciplines, disciplineElement]);
            setShowModal(true);
        },
        [disciplineData, filteredDisciplines]
    );

    const listDisciplines = useMemo(() => {
        return filteredDisciplines.filter((item) =>
            item?.name
                ?.toLocaleUpperCase()
                ?.includes(disciplineSearch?.toLocaleUpperCase())
        );
    }, [filteredDisciplines, disciplineSearch]);

    const onRemoveAvailability = useCallback(
        (index: number, id?: number) => {
            if (!id) {
                const temp = [...newAvailabilities];
                temp.splice(index, 1);
                setNewAvailabilities(temp);

                return;
            }

            setAvailabilities(availabilities.filter((item) => item.id !== id));
            setRemovedAvailabilities([...removedAvailabilities, id]);
            setChangedAvailabilities([
                changedAvailabilities.filter((item) => item.id !== id),
            ]);
            setShowModal(true);
        },
        [
            newAvailabilities,
            availabilities,
            removedAvailabilities,
            changedAvailabilities,
        ]
    );

    const handleChange = useCallback(
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });

            setShowModal(true);
        },
        [values]
    );

    const onSubmit = useCallback(async () => {
        setSubimitting(true);

        if (id) {

            const obj = {
                ...values,
                id,
                addedAvailabilities: changedAvailabilities.map((item) => ({
                    ...item,
                    teacherId: id,
                    classPeriodId: item.classPeriodId,
                })),
                removedAvaliabilities,
                removeDiscipline,
            };

            await updateTeacher(id, obj);
            navigate(0);
        } else {
            const obj = {
                ...values,
                availabilities: changedAvailabilities.map((item) => ({
                    ...item,
                    teacherId: id,
                    classPeriodId: item.classPeriodId,
                })),
                disciplines: filteredDisciplines,
            };

            const res: any = await postTeacher(obj);
            console.log(obj, res)
            navigate(`/professores/${res?.data?.id}`);
            //navigate(0);
        }
    }, [changedAvailabilities, filteredDisciplines, id, navigate, removeDiscipline, removedAvaliabilities, values]);

    if (loading) {
        return (
            <LoadingWrapper>
                <Loading
                    type="spinningBubbles"
                    color={"rgb(9, 160, 17)"}
                    width={30}
                    height={30}
                />
            </LoadingWrapper>
        );
    }

    return (
        <Container>
            <BackButton
                onClick={() => {
                    showModal
                        ? confirmAlert({
                              ...options,
                              customUI: ({ onClose }) => {
                                  return (
                                      <ModalContainer>
                                          <h1>Aviso</h1>
                                          <p>
                                              Todos os dados não salvos serão
                                              apagados.
                                          </p>
                                          <div className="buttons">
                                              <button
                                                  onClick={() => {
                                                      navigate("/professores");
                                                      onClose();
                                                  }}
                                              >
                                                  Ok
                                              </button>
                                              <button onClick={onClose}>
                                                  Cancelar
                                              </button>
                                          </div>
                                      </ModalContainer>
                                  );
                              },
                          })
                        : navigate("/professores");
                }}
            >
                <ArrowBackIosNewIcon fontSize="small" /> Voltar
            </BackButton>

            <Content>
                <Form>
                    <Header>
                        <Stack width={"94%"}>
                            {!edit.data && !!id ? (
                                <>
                                    <TextField
                                        label="Nome:"
                                        size="small"
                                        value={data?.name}
                                        onChange={handleChange("name")}
                                        fullWidth
                                        disabled={true}
                                        variant="standard"
                                    />
                                </>
                            ) : (
                                <TextField
                                    label="Nome"
                                    size="small"
                                    value={values?.name}
                                    onChange={handleChange("name")}
                                    fullWidth
                                    disabled={!edit.data}
                                    variant="outlined"
                                />
                            )}
                        </Stack>
                        {!!id && (
                            <>
                                <EditButton
                                    type="button"
                                    onClick={() =>
                                        setEdit({ ...edit, data: !edit.data })
                                    }
                                >
                                    Editar
                                </EditButton>
                            </>
                        )}
                    </Header>

                    <FormControlLabel
                        disabled={!edit.data}
                        control={
                            <Switch
                                size="small"
                                checked={values?.isActive}
                                onChange={() =>
                                    setValues((prevState) => ({
                                        ...prevState,
                                        isActive: !prevState.isActive,
                                    }))
                                }
                                inputProps={{ "aria-label": "isActive" }}
                            />
                        }
                        label="Ativo"
                    />
                </Form>
                <SelectContainer>
                    <List>
                        <Header>
                            <p>Disponibilidades</p>
                            {!!id && (
                                <EditButton
                                    type="button"
                                    onClick={() =>
                                        setEdit({
                                            ...edit,
                                            availabilities:
                                                !edit.availabilities,
                                        })
                                    }
                                >
                                    Editar
                                </EditButton>
                            )}
                        </Header>
                        <DaysPicker
                            periods={iperiods}
                            teacherAvaliability={data.teacherAvailabilities}
                            isEditing={edit.availabilities}
                            setChangedAvailabilities={setChangedAvailabilities}
                            setRemoveAvaliabilities={setRemoveAvaliabilities}
                        />

                        {newAvailabilities.map((item, index) => (
                            <ListOption>
                                <AvailabilityContainer>
                                    <TextField
                                        select
                                        size="small"
                                        value={item.dayOfWeek}
                                        onChange={(e) =>
                                            onChangeNewAvailability(
                                                index,
                                                "dayOfWeek",
                                                e.target.value
                                            )
                                        }
                                        sx={{
                                            width: "100%",
                                            maxWidth: "300px",
                                        }}
                                        disabled={!edit.availabilities}
                                    >
                                        <MenuItem value="segunda">
                                            segunda
                                        </MenuItem>
                                        <MenuItem value="terça">terça</MenuItem>
                                        <MenuItem value="quarta">
                                            quarta
                                        </MenuItem>
                                        <MenuItem value="quinta">
                                            quinta
                                        </MenuItem>
                                        <MenuItem value="sexta">sexta</MenuItem>
                                        <MenuItem value="sábado">
                                            sabado
                                        </MenuItem>
                                        <MenuItem value="domingo">
                                            domingo
                                        </MenuItem>
                                    </TextField>
                                    <TextField
                                        placeholder="08:00"
                                        value={item.start}
                                        size="small"
                                        onChange={(e) =>
                                            onChangeNewAvailability(
                                                index,
                                                "start",
                                                e.target.value
                                            )
                                        }
                                        disabled={!edit.availabilities}
                                    />
                                    <TextField
                                        placeholder="08:00"
                                        value={item.end}
                                        size="small"
                                        onChange={(e) =>
                                            onChangeNewAvailability(
                                                index,
                                                "end",
                                                e.target.value
                                            )
                                        }
                                        disabled={!edit.availabilities}
                                    />
                                </AvailabilityContainer>
                                {edit.availabilities && (
                                    <Tooltip title={"Remover disponibilidade"}>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                onRemoveAvailability(
                                                    index,
                                                    item.id
                                                )
                                            }
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
                <SelectContainer>
                    <Header>
                        <p>Disciplinas</p>
                        {!!id && (
                            <EditButton
                                editing={edit.disciplines}
                                type="button"
                                onClick={() =>
                                    setEdit({
                                        ...edit,
                                        disciplines: !edit.disciplines,
                                    })
                                }
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>

                    <AddSection>
                        {edit.disciplines ? (
                            <Autocomplete
                                options={disciplineOptions}
                                value={disciplineValue}
                                onChange={(e, v) => setDisciplineValue(v)}
                                size="small"
                                fullWidth
                                disabled={!edit.disciplines}
                                renderInput={(params) => (
                                    <TextField
                                        label={"Disciplina"}
                                        {...params}
                                    />
                                )}
                            />
                        ) : (
                            <TextField
                                label="Pesquisar disciplina"
                                size="small"
                                value={disciplineSearch}
                                onChange={(e) =>
                                    setDisciplineSearch(e.target.value)
                                }
                                fullWidth
                            />
                        )}
                        <Button
                            disabled={!edit.disciplines}
                            variant="contained"
                            onClick={() => {
                                addDiscipline(disciplineValue.value);
                                setDisciplineValue({ label: "", value: -1 });
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </AddSection>
                    <List>
                        {listDisciplines?.map((c) => (
                            <ListOption key={c.id}>
                                <Link to={`/disciplinas/${c.id}`}>
                                    {c.discipline || c.name}
                                </Link>
                                {edit.disciplines && (
                                    <Tooltip title={"Remover disciplina"}>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                removeDiscipline(c.id)
                                            }
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
                <SelectContainer>
                    <Header>
                        <p>Turmas</p>
                    </Header>

                    <AddSection>
                        <TextField
                            label="Pesquisar turma"
                            size="small"
                            value={classSearch}
                            onChange={(e) => setClassSearch(e.target.value)}
                            fullWidth
                        />
                    </AddSection>
                    <List>
                        {listClasses?.map((c) => (
                            <ListOption key={c.id}>
                                <Link to={`/turmas/${c.id}`}>
                                    {c.name} - {c.segment} - {c.shift}
                                </Link>
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
            </Content>
            {(edit.availabilities || edit.data || edit.disciplines) && (
                <Buttons>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={submitting}
                    >
                        Enviar
                    </Button>
                </Buttons>
            )}
        </Container>
    );
};

export default TeacherEdit;
