import { useCallback, useEffect, useState } from "react";
import { Formik, useField } from "formik";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    AlignContainer,
    Container,
    Form,
    ImageContainer,
} from "./login.module";
import LoadingButton from "@mui/lab/LoadingButton";
import OmegaLogo from "../../assets/images/logo-omegaco-completa-fundo-tranparente.png";

import { clearToken, loginToken } from "./tokenSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleToast } from "../../components/Toast";
import App from "../../../package.json";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.token);
    const [loading, setLoading] = useState<boolean>(false);
    const [seePass, setSeePass] = useState<boolean>(false);

    const login = useCallback(
        async (username: string, password: string) => {
            const response = await dispatch(loginToken({ username, password }));
            return response.payload;
        },
        [dispatch]
    );

    const authenticateUser = async (loginData) => {
        setLoading(true);
        const { username, password } = loginData;

        try {
            const response = await login(username, password);

            if (!response.data) {
                setLoading(false);
                dispatch(clearToken());
                return handleToast(
                    true,
                    "Verifique suas credenciais e tente novamente"
                );
            }
            setLoading(false);
            handleToast(false, "Login efetuado com sucesso");

            return navigate("/");
        } catch (e) {
            setLoading(false);
            handleToast(true, "Credencial inválida");
        }
    };

    useEffect(() => {
        if (token.data) {
            navigate("/");
        }
    }, [navigate, token, loading]);

    if (token.data) {
        return null;
    }

    return (
        <Container className="teste">
            <AlignContainer>
                <h3>Login</h3>
                <Formik
                    onSubmit={authenticateUser}
                    initialValues={{ username: "", password: "" }}
                >
                    <Form>
                        <Input
                            name="username"
                            label="Usuário"
                            size="medium"
                            fullWidth
                        />
                        <Input
                            name="password"
                            label="Senha"
                            size="medium"
                            fullWidth
                            type={seePass ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setSeePass(!seePass)}
                                        >
                                            {seePass ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading}
                            fullWidth
                            size="medium"
                        >
                            Entrar
                        </LoadingButton>
                    </Form>
                </Formik>
            </AlignContainer>
            <ImageContainer>
                <span>Created by </span>
                <img src={OmegaLogo} alt="omega" width={100} />
            </ImageContainer>
            {process.env.NODE_ENV === "development" && (
                <span style={{ fontSize: 14, marginTop: 10 }}>
                    Versão de desenvolvimento, v{App.version}
                </span>
            )}
        </Container>
    );
}

export default Login;

export function Input(props: any) {
    const [field, meta] = useField(props);

    return (
        <>
            <TextField {...props} {...field} />
            {meta.error && <span>{meta.error}</span>}
        </>
    );
}
