import { TextField } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    width: 100%;
    flex: 1;

    gap: 1.5rem;
`;

export const Wrapper = styled.section`
    display: flex;
    padding: 10px;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
`;

export const Row = styled.section`
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    align-items: center;
    width: 100%;
    gap: 0.75rem;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

export const Section = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    background: white;
    padding: 10px;
`;

export const CalendarSave = styled.section`
    width: 100%;
`;

export const InputField = styled(TextField)`
    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const InputRow = styled.section`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;

    gap: 0.75rem;
`;

export const SideSection = styled.section`
    width: 100%;
    max-width: 430px;

    background: white;
    max-height: 600px;
    overflow-y: hidden;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .MuiInputBase-root {
        background: transparent !important;
    }
`;

export const ListDiv = styled.div`
    overflow-y: auto;
`

export const EditIcon = styled.button`
    background: none;
    outline: none;
    border: none;
    justify-self: flex-end;
    opacity: 0.6;

    cursor: pointer;
    border-radius: 50%;
    padding: 4px;

    transition: 0.2s;

    :hover {
        background: rgba(0, 0, 0, 0.05);
    }

    :active {
        background: rgba(0, 0, 0, 0.3);
    }
`;

export const PaginationContainer = styled.div`
    align-self: center;

    .MuiPaginationItem-page {
        background: rgba(0, 0, 0, 0.05);
        color: var(--primary-green);

        :hover {
            background: var(--primary-green);
            color: white;
        }
    }

    .Mui-selected {
        background: var(--primary-green) !important;
        color: white !important;
    }
`;

export const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 430px;
`;
