import { createTheme } from "@mui/material";

const isDevelopment = process.env.REACT_APP_ENV === 'development';

export const theme = createTheme({
    palette: {
        primary: {
            main: isDevelopment ? 'rgb(0, 0, 0)' : 'rgb(9, 160, 17)'
        }
    }
});
