// A mock function to mimic making an async request for data

import Api from "../../services/api";

interface IAvailability {
    availabilityId?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

export function fetchAvailabilities(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get("/teacher-availabilities", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function getAvailabilityById(id: number) {
    return new Promise((resolve, reject) => {
        Api.get(`/teacher-availabilities/${id}`)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function getAvailabilityByTeacherId(id: number) {
    return new Promise((resolve, reject) => {
        Api.get(`/teacher/${id}`)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function postAvailability(data: IAvailability) {
    return new Promise((resolve, reject) => {
        Api.post("/teacher-availabilities", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updateAvailability(data: any) {
    return new Promise((resolve, reject) => {
        Api.put(`/teacher-availabilities/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteAvailability(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/teacher-availabilities/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
