import React from "react";

import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import { Container, ModalContainer } from "./discipline.module";
import { deleteDiscipline } from "./disciplineAPI";
import { getDisciplinesAsync } from "./disciplineSlice";
import { getClassesAsync } from "../class/classSlice";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: "overlay-custom-class-name",
};

export function Discipline() {
    const dispatch = useAppDispatch();

    const { data, status } = useAppSelector((state) => state.disciplines);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(getDisciplinesAsync({ page, rowsPerPage }));
        dispatch(getClassesAsync({ page: -1 }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const handleDelete = React.useCallback(
        (id: number) => {
            deleteDiscipline({ id: id, isActive: false })
                .then(() => {
                    handleToast(false, "Disciplina deletada com sucesso.");
                    return dispatch(getDisciplinesAsync({ page }));
                })
                .catch((error) => {
                    handleToast(true, error.message);
                    return;
                });
        },
        [dispatch, page]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                getDisciplinesAsync({
                    name: event.target.value,
                })
            );
        },
        [dispatch]
    );

    return (
        <Container className="daschboard-container">
            <Table
                columns={[
                    {
                        title: "Disciplina",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Carga Horária",
                        key: "workload",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar disciplina",
                        onClick: (rowData) => {
                            navigate(`/disciplinas/${rowData.id}`);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar disciplina",
                        onClick: (rowData) => {
                            return confirmAlert({
                                ...options,
                                customUI: ({ onClose }) => {
                                    return (
                                        <ModalContainer>
                                            <h1>Aviso</h1>
                                            <p>
                                                Tem certeza que deseja remover
                                                esta disciplina?
                                            </p>
                                            <div className="buttons">
                                                <button
                                                    onClick={() => {
                                                        handleDelete(
                                                            rowData?.id
                                                        );

                                                        onClose();
                                                    }}
                                                >
                                                    Sim
                                                </button>
                                                <button onClick={onClose}>
                                                    Cancelar
                                                </button>
                                            </div>
                                        </ModalContainer>
                                    );
                                },
                            });
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => navigate("/disciplinas/criar")}
                onClickRefresh={() => dispatch(getDisciplinesAsync({ page }))}
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Disciplinas"
            />
        </Container>
    );
}
