import React, { useCallback } from "react";
import {
    Body,
    Cell,
    Container,
    Content,
    DateNumberContainer,
    Day,
    DaysWrapper,
    DayWrapper,
    Header,
    InputColor,
    Row,
    RowDrawer,
    Title,
    TitleContainer,
} from "./styles";
import { Button, IconButton, Drawer, List, ListItem } from "@mui/material";
import * as dateFns from "date-fns";
import { ptBR } from "date-fns/esm/locale";
import { UsePrint } from "../../hooks/usePrint";
import DraggableCell from "./DraggableCell";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
    getCalendarAsync,
    ICalendarState,
} from "../../features/calendar/calendarSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { getXlsx, ICalendarSave } from "./indexApi";
import { postCalendarSaveAsync, updateCalendarSaveAsync } from "./indexSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { InputField } from "../Table/styles";
import { handleToast } from "../Toast";

interface IPeriod {
    id?: number;
    start: string;
    end: string;
}

interface IDiscipline {
    id: number;
    workload: number | null;
    isActive: boolean;
    discipline: string;
    classes: Array<any>;
    shift: {
        shiftId: number;
        shift: string;
    };
}

interface ITeacher {
    id: number;
    name: string;
    isActive: boolean;
    discipline: IDiscipline;
    TeacherAvailabilities?: IAvailability[];
}

interface IAvailability {
    id?: number;
    dayOfWeek: string;
    start: string;
    end: string;
    Teacher: ITeacher;
}

interface IProps {
    type: "monthly" | "weekly" | "daily";
    period?: number;
    data?: any;
    startTime?: string;
    module?: string;
    shift?: string;
    shiftId?: number;
    segmentId?: number;
    startDate?: Date;
    endDate?: Date;
    currentDate?: Date;
    periods: IPeriod[];
    workloadCounter?: object;
    month: string;
    handleWorkload?: (discipline: string) => void;
    className?: string;
    classId?: number;
    calendarId?: number;
    disciplineHours: any[];
}

export default function Calendar(props: IProps) {
    const { setComponentToPrintPDF, handlePrintPDF, setTitle, showButtons } =
        UsePrint();
    const { data: calendarData } = useAppSelector<ICalendarState>(
        (state) => state.calendars
    );

    const dispatch = useAppDispatch();

    const {
        type,
        data,
        startDate,
        endDate,
        currentDate,
        module,
        shift,
        shiftId,
        segmentId,
        month,
        className,
        classId,
        calendarId,
        disciplineHours,
    } = props;

    const currRef = React.useRef<HTMLDivElement | null>(null);

    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
    const [calendarTitle, setCalendarTitle] = React.useState<string>("");
    const [open, setOpen] = React.useState<boolean>(false);
    const [, setColor] = React.useState<string>("");
    const [dateDrawer] = React.useState<string>("");

    React.useEffect(() => {
        dispatch(getCalendarAsync({ page: -1, rowPerPage: 40 }));
    }, [dispatch]);

    React.useEffect(() => {
        if (currRef) setComponentToPrintPDF(currRef);
    }, [currRef, setComponentToPrintPDF]);

    React.useEffect(() => {
        let monthName = dateFns.format(currentDate, "MMMM", {
            locale: ptBR,
        });
        setCalendarTitle(`${module}_${shift}_${monthName}`);
    }, [currentDate, module, shift]);

    const onDateClicked = (value: Date) => {
        setSelectedDate(value);
    };

    const toggleDrawer = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const saveCalendar = () => {
        if (
            !data.month ||
            !className ||
            !module ||
            !currentDate ||
            !shift ||
            !shiftId
        ) {
            return handleToast(true, "Não foi possivel salvar o calendario");
        }

        const json = {
            calendar: data,
            disciplineHours,
        };

        const calendar: ICalendarSave = {
            calendar: json,
            shift: shift,
            shiftId: shiftId,
            segment: module,
            segmentId: segmentId,
            class: className,
            classId: classId,
            startDate: dateFns.format(startDate, "yyyy-MM-dd"),
            endDate: dateFns.format(endDate, "yyyy-MM-dd"),
            month: data.month,
        };

        if (calendarId) {
            dispatch(updateCalendarSaveAsync(calendar));
        } else {
            dispatch(postCalendarSaveAsync(calendar));
        }
    };

    const printXlsx = async () => {
        try {
            const response = await getXlsx(data.calendar);
            const doc = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(doc);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${classId}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {}
    };

    const renderHeader = () => {
        const dateFormat = "MMMM";

        return (
            <Header className="calendar-header">
                <TitleContainer>
                    <Title>
                        {dateFns.format(currentDate, dateFormat, {
                            locale: ptBR,
                        })}{" "}
                        - {className}
                    </Title>
                    {module} - {shift}
                </TitleContainer>
                {showButtons && (
                    <>
                        <Button
                            variant="text"
                            onClick={() => {
                                setTitle(calendarTitle);
                                handlePrintPDF();
                                saveCalendar();
                            }}
                        >
                            Imprimir
                        </Button>
                        <Button variant="text" onClick={saveCalendar}>
                            Salvar
                        </Button>
                        <Button variant="text" onClick={printXlsx}>
                            Baixar
                        </Button>
                    </>
                )}
            </Header>
        );
    };

    const renderDays = () => {
        const dateFormat = "eee";
        const days = [];
        let options = {
            locale: ptBR,
        };

        if (type === "daily") {
            options["weekStartsOn"] = 1;

            days.push(
                <DayWrapper key={"hr"}>
                    <Day>Horário</Day>
                </DayWrapper>
            );
        }

        const startDateNew = dateFns.startOfWeek(startDate, options);

        for (let i = 0; i < 7; i++) {
            days.push(
                <DayWrapper key={i.toString()}>
                    <Day>
                        {dateFns.format(
                            dateFns.addDays(startDateNew, i),
                            dateFormat,
                            options
                        )}
                    </Day>
                </DayWrapper>
            );
        }

        return <DaysWrapper className="week-draggable">{days}</DaysWrapper>;
    };

    const renderCellData = React.useCallback(
        (dayOfWeeks: string, holiday: boolean, dayInformation: any) => {
            return dayInformation?.periods?.map((period) => {
                if (period) {
                    return (
                        <DraggableCell
                            data={dayInformation}
                            period={period}
                            teacher={period}
                            periodStart={period?.start}
                            dayOfWeek={dayOfWeeks}
                            name={period?.teacher}
                            discipline={period?.discipline}
                            month={month}
                            holiday={holiday}
                        />
                    );
                } else {
                    return null;
                }
            });
        },
        [month]
    );

    const checkIsHoliday = React.useCallback(
        (day: Date) => {
            if (!calendarData) return false;

            const holidays = calendarData.map(({ date }: any) =>
                dateFns.addDays(new Date(date), 1)
            );

            return holidays.some((date) => dateFns.isSameDay(date, day));
        },
        [calendarData]
    );

    const renderMonthlyCells = () => {
        const startDateNew = dateFns.startOfWeek(startDate);
        const endOfMonth = dateFns.endOfMonth(startDate);
        const endDateNew = dateFns.endOfMonth(
            dateFns.isBefore(endOfMonth, endDate) ? endOfMonth : endDate
        );

        const dateFormat = "dd";
        let rows = [];
        let days = [];
        let day = startDateNew;
        let formattedDate = "";
        while (day <= endDateNew) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                let dayOfWeek = dateFns.format(day, "eee", { locale: ptBR });
                const cloneDay = day;

                const dayIndex = dateFns.getDate(day);
                const dayInformation = data?.calendar?.find(
                    (d) => d.day === dayIndex
                );

                days.push(
                    <Cell
                        className={`calendar-day ${
                            !dateFns.isSameMonth(day, startDate)
                                ? "disabled"
                                : dateFns.isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                        }`}
                        key={day.toString()}
                        onClick={() => onDateClicked(cloneDay)}
                        holiday={checkIsHoliday(day)}
                        list={formattedDate}
                    >
                        <DateNumberContainer>
                            <div className="day">
                                {formattedDate}
                                <IconButton onClick={toggleDrawer}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </div>
                        </DateNumberContainer>
                        {dateFns.isSameMonth(
                            day,
                            dateFns.startOfWeek(endDateNew)
                        )
                            ? renderCellData(
                                  dayOfWeek,
                                  checkIsHoliday(day),
                                  dayInformation
                              )
                            : null}
                    </Cell>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(<Row key={day.toString()}>{days}</Row>);
            days = [];
        }

        return <Body className="calendar-body">{rows}</Body>;
    };

    return (
        <Container ref={currRef}>
            <Content>
                {renderHeader()}
                {renderDays()}
                {type === "monthly" && renderMonthlyCells()}
                {/* {type === 'daily' && renderDailyCells(data)} */}
                <Drawer anchor="left" open={open} onClose={toggleDrawer}>
                    <List>
                        <ListItem>
                            <RowDrawer>
                                <span>Diferenciar dia por cor: </span>
                                <InputColor
                                    type="color"
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </RowDrawer>
                        </ListItem>
                        <ListItem onClick={toggleDrawer}>
                            <DatePicker
                                label="Início do período"
                                value={dateDrawer}
                                onChange={() => {}}
                                renderInput={(params) => (
                                    <InputField size="small" {...params} />
                                )}
                            />
                        </ListItem>
                        <ListItem onClick={toggleDrawer}>
                            <DatePicker
                                label="Final do período"
                                value={dateDrawer}
                                onChange={() => {}}
                                renderInput={(params) => (
                                    <InputField size="small" {...params} />
                                )}
                            />
                        </ListItem>
                    </List>
                </Drawer>
            </Content>
        </Container>
    );
}
