import { useEffect, useCallback, useState, useMemo } from "react";
import {
    Buttons,
    Container,
    Content,
    Form,
    ListOption,
    List,
    SelectContainer,
    Header,
    EditButton,
    AddSection,
    LoadingWrapper,
    BackButton,
} from "./disciplineEdit.module";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    getDisciplineById,
    postDiscipline,
    updateDiscipline,
} from "../discipline/disciplineAPI";
import {
    Autocomplete,
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import MaskedInput from "react-text-mask";
import { Delete } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getTeachersAsync } from "../teacher/teacherSlice";
import { getClassesAsync } from "../class/classSlice";
import AddIcon from "@mui/icons-material/Add";
import Loading from "react-loading";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { confirmAlert } from "react-confirm-alert";
import { ModalContainer } from "../teacherEdit/teacherEdit.module";

const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: "overlay-custom-class-name",
};

const DisciplineEdit = () => {
    const { id } = useParams();
    const { data: teacherData } = useAppSelector((state) => state.teachers);
    const { data: classData } = useAppSelector((state) => state.class);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [teacherValue, setTeacherValue] = useState({ label: "", value: -1 });
    const [classValue, setClassValue] = useState({ label: "", value: -1 });

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({
        name: "",
        workload: "",
        isActive: false,
    });
    const [edit, setEdit] = useState({
        classes: !id,
        teachers: !id,
        data: !id,
    });

    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);

    const [teacherSearch, setTeacherSearch] = useState("");
    const [classSearch, setClassSearch] = useState("");

    const [submitting, setSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const teacherOptions = useMemo(() => {
        if (teacherData?.length) {
            return teacherData?.map((teacher) => ({
                label: `${teacher.name} - ${teacher.discipline}`,
                value: teacher.id,
            }));
        }

        return [];
    }, [teacherData]);

    const classOptions = useMemo(() => {
        if (classData?.length) {
            return classData.map((classes) => ({
                label: `${classes.name} - ${classes.segmentName} - ${classes.shiftName}`,
                value: classes.id,
            }));
        }

        return [];
    }, [classData]);

    const handleEdit = useCallback((property: string) => {
        setEdit((prevState) => ({
            ...prevState,
            [property]: !prevState[property],
        }));
        setShowModal(true);
    }, []);

    const getDiscipline = useCallback(async () => {
        setLoading(true);
        if (!id) {
            return setLoading(false);
        }

        try {
            const response: any = await getDisciplineById({ id });
            if (response?.data?.data) {
                return setData(response?.data?.data);
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }, [id]);

    const handleChange = useCallback(
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });
        },
        [values]
    );

    useEffect(() => {
        getDiscipline();
        dispatch(getTeachersAsync({ page: -1 }));
        dispatch(getClassesAsync({ page: -1 }));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredTeachers(data?.teachers || []);
        setFilteredClasses(data?.classes || []);
    }, [data]);

    const removeTeacher = useCallback(
        (id: number) => {
            if (!filteredTeachers?.length) return;

            setFilteredTeachers(
                filteredTeachers.filter((teacher) => teacher?.id !== id)
            );
            setShowModal(true);
        },
        [filteredTeachers]
    );

    const addTeacher = useCallback(
        (id: number) => {
            if (id === -1) return;

            const foundTeacher = filteredTeachers.find((t) => t?.id === id);

            if (foundTeacher) return;

            const teacherElement = teacherData?.find((t) => {
                return t?.id === id;
            });

            setFilteredTeachers([...filteredTeachers, teacherElement]);
            setShowModal(true);
        },
        [teacherData, filteredTeachers]
    );
    const removeClass = useCallback(
        (id: number) => {
            if (!filteredClasses?.length) return;

            setFilteredClasses(filteredClasses.filter((c) => c?.id !== id));
            setShowModal(true);
        },
        [filteredClasses]
    );

    const addClass = useCallback(
        (id: number) => {
            if (id === -1) return;

            const foundClass = filteredClasses.find((c) => c?.id === id);
            if (foundClass) return;

            const classElement = classData?.find((c) => {
                return c?.id === id;
            });

            setFilteredClasses([...filteredClasses, classElement]);
        },
        [classData, filteredClasses]
    );

    const listTeachers = useMemo(() => {
        return filteredTeachers.filter((item) =>
            item?.name
                ?.toLocaleUpperCase()
                ?.includes(teacherSearch?.toLocaleUpperCase())
        );
    }, [filteredTeachers, teacherSearch]);

    const listClasses = useMemo(() => {
        return filteredClasses.filter((item) =>
            item?.name
                ?.toLocaleUpperCase()
                ?.includes(classSearch?.toLocaleUpperCase())
        );
    }, [filteredClasses, classSearch]);

    useEffect(() => {
        if (id) {
            setValues((prevState) => ({
                ...prevState,
                name: data.name || "",
                workload: data.workload || "",
                isActive: data.isActive || false,
            }));
        }
    }, [data, id]);

    const onSubmit = useCallback(async () => {

        setSubmitting(true);
        
        const removedTeachers =
            data?.teachers
                ?.filter(
                    (teacher) =>
                        !filteredTeachers.map((t) => t.id).includes(teacher.id)
                )
                ?.map((item) => item.id) || [];

        const addedTeachers = filteredTeachers
            .filter((teacher) => {
                if (data?.teachers?.length) {
                    return !data.teachers.map((t) => t.id).includes(teacher.id);
                }

                return true;
            })
            .map((item) => item.id);

        const removedClasses =
            data?.classes
                ?.filter(
                    (classes) =>
                        !filteredClasses.map((c) => c.id).includes(classes.id)
                )
                ?.map((item) => item.id) || [];

        const addedClasses = filteredClasses
            .filter((classes) => {
                if (data?.classes?.length) {
                    return !data.classes.map((c) => c.id).includes(classes.id);
                }

                return true;
            })
            .map((item) => item.id);

        if (id) {
            await updateDiscipline({
                ...values,
                addedClasses,
                addedTeachers,
                removedClasses,
                removedTeachers,
                id,
            });

            navigate(0);
        } else {
            const res: any = await postDiscipline({
                ...values,
                classes: addedClasses,
                teachers: addedTeachers,
            });

            navigate(`/disciplinas/${res?.data?.id}`);
            navigate(0);
        }

        setSubmitting(false);
    }, [values, filteredTeachers, filteredClasses, data, id, navigate]);

    if (loading)
        return (
            <LoadingWrapper>
                <Loading
                    type="spinningBubbles"
                    color={"rgb(9, 160, 17)"}
                    width={30}
                    height={30}
                />
            </LoadingWrapper>
        );

    return (
        <Container>
            <BackButton
                onClick={() => {
                    showModal
                        ? confirmAlert({
                              ...options,
                              customUI: ({ onClose }) => {
                                  return (
                                      <ModalContainer>
                                          <h1>Aviso</h1>
                                          <p>
                                              Todos os dados não salvos serão
                                              apagados.
                                          </p>
                                          <div className="buttons">
                                              <button
                                                  onClick={() => {
                                                      navigate("/disciplinas");
                                                      onClose();
                                                  }}
                                              >
                                                  Ok
                                              </button>
                                              <button onClick={onClose}>
                                                  Cancelar
                                              </button>
                                          </div>
                                      </ModalContainer>
                                  );
                              },
                          })
                        : navigate("/disciplinas");
                }}
            >
                <ArrowBackIosNewIcon fontSize="small" /> Voltar
            </BackButton>
            <Content>
                <Form>
                    <Header>
                        <p>Informações</p>
                        {!!id && (
                            <EditButton
                                editing={edit.data}
                                type="button"
                                onClick={() => handleEdit("data")}
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>
                    {!edit.data && !!id ? (
                                <>
                                    <TextField
                                        label={`Disciplina: ${data.discipline}`}
                                        size="small"
                                        value={values?.name}
                                        onChange={handleChange("name")}
                                        fullWidth
                                        disabled={true}
                                        variant="standard"
                                    />
                                </>
                            ) : (
                                <TextField
                                    label={`${data.discipline}`}
                                    size="small"
                                    value={data?.name}
                                    onChange={handleChange("name")}
                                    fullWidth
                                    disabled={!edit.data}
                                    variant="outlined"
                                />
                            )}
                    <MaskedInput
                        mask={() => {
                            const arr = [];

                            for (let i = 0; i < 100; i++) {
                                arr.push(/\d/);
                            }

                            return arr;
                        }}
                        guide={false}
                        value={values?.workload}
                        onChange={handleChange("workload")}
                        disabled={!edit.data}
                        render={(ref, props) => (
                            <TextField
                                inputRef={ref}
                                label="Carga horaria"
                                placeholder="60"
                                size="small"
                                name="workload"
                                fullWidth
                                autoComplete="off"
                                {...props}
                            />
                        )}
                    />
                    <FormControlLabel
                        disabled={!edit.data}
                        control={
                            <Switch
                                size="small"
                                checked={values?.isActive}
                                onChange={() =>
                                    setValues((prevState) => ({
                                        ...prevState,
                                        isActive: !prevState.isActive,
                                    }))
                                }
                                inputProps={{ "aria-label": "isActive" }}
                            />
                        }
                        label="Ativo"
                    />
                </Form>
                <SelectContainer>
                    <Header>
                        <p>Professores</p>
                        {!!id && (
                            <EditButton
                                editing={edit.teachers}
                                type="button"
                                onClick={() => handleEdit("teachers")}
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>
                    <AddSection>
                        {edit.teachers ? (
                            <Autocomplete
                                options={teacherOptions}
                                value={teacherValue}
                                fullWidth
                                onChange={(e, v) => setTeacherValue(v)}
                                size="small"
                                disabled={!edit.teachers}
                                renderInput={(params) => (
                                    <TextField
                                        label={"Professor"}
                                        {...params}
                                    />
                                )}
                            />
                        ) : (
                            <TextField
                                label="Pesquisar professor"
                                size="small"
                                value={teacherSearch}
                                onChange={(e) =>
                                    setTeacherSearch(e.target.value)
                                }
                                fullWidth
                            />
                        )}
                        <Button
                            disabled={!edit.teachers}
                            variant="contained"
                            onClick={() => {
                                setTeacherValue({ label: "", value: -1 });
                                addTeacher(teacherValue?.value);
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </AddSection>
                    <List>
                        {listTeachers?.map((teacher) => (
                            <ListOption key={teacher.id}>
                                <Link to={`/professores/${teacher.id}`}>
                                    {teacher.name}
                                </Link>
                                {edit.teachers && (
                                    <Tooltip title={"Remover professor"}>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                removeTeacher(teacher.id)
                                            }
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
                <SelectContainer>
                    <Header>
                        <p>Turmas</p>
                        {!!id && (
                            <EditButton
                                editing={edit.classes}
                                type="button"
                                onClick={() => handleEdit("classes")}
                            >
                                Editar
                            </EditButton>
                        )}
                    </Header>

                    <AddSection>
                        {edit.classes ? (
                            <Autocomplete
                                options={classOptions}
                                value={classValue}
                                onChange={(e, v) => setClassValue(v)}
                                size="small"
                                fullWidth
                                disabled={!edit.classes}
                                renderInput={(params) => (
                                    <TextField label={"Turma"} {...params} />
                                )}
                            />
                        ) : (
                            <TextField
                                label="Pesquisar turma"
                                size="small"
                                value={classSearch}
                                onChange={(e) => setClassSearch(e.target.value)}
                                fullWidth
                            />
                        )}
                        <Button
                            disabled={!edit.classes}
                            variant="contained"
                            onClick={() => {
                                setClassValue({ label: "", value: -1 });
                                addClass(classValue.value);
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </AddSection>
                    <List>
                        {listClasses?.map((c) => (
                            <ListOption key={c.id}>
                                {c.name} - {c?.segment?.name || c.segmentName} -{" "}
                                {c?.shift?.name || c.shiftName}
                                {edit.classes && (
                                    <Tooltip title={"Remover turma"}>
                                        <IconButton
                                            size="small"
                                            onClick={() => removeClass(c.id)}
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListOption>
                        ))}
                    </List>
                </SelectContainer>
            </Content>
            <Buttons>
                {(edit.classes || edit.data || edit.teachers || !id) && (
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={submitting}
                    >
                        Enviar
                    </Button>
                )}
            </Buttons>
        </Container>
    );
};

export default DisciplineEdit;
